import React from 'react'
import { Santotomas } from './logos/santotomas'
import { Usach } from './logos/usach'
import { Inacap } from './logos/inacap'
import { Aiep } from './logos/aiep'

export const First = (props) => {
  return (
    <>
      <section id="first" className="main special">
        <header className="major">
          <h2>Principales Clientes</h2>
        </header>
        <ul className="features">
          <li>
            <Santotomas/>
          </li>
          <li>
            <Usach/>
          </li>
          <li>
            <Inacap/>
          </li>
          <li>
            <Aiep/>
          </li>

        </ul>
      </section>
    </>
  )
}
