import React from 'react'
import logo from '../../../assets/img/santotomas.png'
import { Logocontainer } from './logocontainer'
export const Santotomas = (props) => {
  return (
    <Logocontainer>
      <img src={logo} alt="Logo" width={150} />
    </Logocontainer>
  )
}
