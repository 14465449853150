import React from 'react'

export const Nav = (props) => {
  return (
    <>
      <nav id="nav">
        <ul>
          <li><a href="#intro" className="active">Introducción</a></li>
          <li><a href="#first">Principales Clientes</a></li>
          <li><a href="#second">Nuestros Productos</a></li>
        </ul>
      </nav>
    </>
  )
}
