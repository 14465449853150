import React from 'react'

export const Logocontainer = ({ children }) => {
  return (
    <>
      <div style={{height:170}} >
        {children}
      </div>
    </>
  )
}
