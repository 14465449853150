import React from 'react'
import { Pics } from './img/pics'

export const Second = (props) => {
  return (
    <>
      <section id="second" className="main special">
        <header className="major">
          <h2>Algunos de nuestros productos</h2>
        </header>

        <ul className="features">
          <li>
            <Pics filename={'pic1.png'}/>
            <h3>Delantal TENS</h3>
            <p>Delantal clínico modelo santo tomás, bordado incluido</p>
          </li>
          <li>
            <Pics filename={'pic2.png'}/>
            <h3>Uniforme clínico veterinaria</h3>
            <p>Modelo santo tomás</p>
          </li>
          <li>
            <Pics filename={'pic3.png'}/>
            <h3>Uniforme centro estética</h3>
            <p>Pedido personalizado modelo niña para centro de estética
              Isabela</p>
          </li>
          <li>
            <Pics filename={'pic4.png'}/>
            <h3>Uniforme barbería</h3>
            <p>Pedido personalizado modelo barón para barbería</p>
          </li>
          <li>
            <Pics filename={'pic5.png'}/>
            <h3>Uniforme clínico completo</h3>
            <p>Modelo Santo tomás</p>
          </li>
          <li>
            <Pics filename={'pic6.png'}/>
            <h3>Uniforme y delantal veterinaria</h3>
            <p>Modelo Santo tomás</p>
          </li>
          <li>
            <Pics filename={'pic7.png'}/>
            <h3>Uniforme clínico veteraria</h3>
            <p>Feliz con su Uniforme clínico a medida para CFT Santo tomás</p>
          </li>
          <li>
            <Pics filename={'pic8.png'}/>
            <h3>Delantal de párvulo</h3>
            <p>Modelo Santo tomás</p>
          </li>
          <li>
            <Pics filename={'pic9.png'}/>
            <h3>Uniforme clínico antifluido</h3>
            <p>Modelo Santo tomás</p>
          </li>

        </ul>

      </section>
    </>
  )
}
