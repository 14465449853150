import React from 'react'
import logo from '../../../assets/img/usach.png'
import { Logocontainer } from './logocontainer'
export const Usach = (props) => {
  return (
    <Logocontainer>
      <img src={logo} alt="Logo" width={120} />
    </Logocontainer>
  )
}
