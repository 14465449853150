import React from 'react'
import pic from '../assets/img/bannerimg.jpg'

export const Intro = (props) => {
  return (
    <>
      <section id="intro" className="main">
        <div className="spotlight">
          <div className="content">
            <header className="major">
              <h2>¿Quienes somos?</h2>
            </header>
            <p>Somos una fábrica especializada en la confección de uniformes
              corporativos tales como: trajes clínicos, pecheras anti-fluido,
              delantales, cortavientos, chaquetas de seguridad, entre otros.</p>
          </div>
          <span className="image" ><img src={pic}
                                       alt=""/></span>
        </div>
      </section>
    </>
  )
}
