import React from 'react'
import './assets/css/main.css'
import { Header } from './component/Header'
import { Nav } from './component/Nav'
import { Intro } from './component/Intro'
import { First } from './component/First/First'
import { Second } from './component/Second/Second'
import { Footer } from './component/Footer'

const App = () => {
  return (
    <>
      <div id="wrapper">
        <Header/>
        <Nav/>
        <div id="main">
          <Intro/>
          <First/>
          <Second/>

        </div>

        <Footer/>
      </div>
    </>
  )
}

export default App
