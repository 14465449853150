import React from 'react'
import logo from '../../../assets/img/aiep.png'
import { Logocontainer } from './logocontainer'
export const Aiep = (props) => {
  return (
    <Logocontainer>
      <img src={logo} alt="Logo" width={300} />
    </Logocontainer>
  )
}
