import React from 'react'

export const Footer = (props) => {
  return (
    <>
      <footer id="footer">
        <section>
          <h2>Atención</h2>
          <p>Todos nuestros trabajos son a medida y con bordado incluido, para poder iniciar la confección de cualquier uniforme se debe abonar el 50% de manera anticipada. Información de precios contactar vía WhatsApp.</p>

        </section>
        <section>
          <h2>Contacto</h2>
          <dl className="alt">
            <dt>Fono</dt>
            <dd><a href='tel:+56984701570'>(+56) 9 8470 1570</a></dd>
            <dt>Email</dt>
            <dd><a href="mailto:brioneslc@gmail.com">brioneslc@gmail.com</a></dd>
          </dl>
          <ul className="icons">
            <li><a href="http://wa.me/56984701570" className="icon brands fa-whatsapp alt"><span
              className="label">Wathsapp</span></a></li>
          </ul>
        </section>
        <p className="copyright">&copy; Unicorp 2021.</p>
      </footer>
    </>
  )
}
