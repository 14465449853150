import React from 'react'

export const Pics = ({ filename  }) => {
const img = require(`../../../assets/img/${filename}`).default
  return (
    <>
      <img src={img} style={{ borderRadius: '50%', border: 'solid gray 1px', maxWidth:'100%', maxHeight:300}}/>
    </>
  )
}
