import React from 'react'
import { Logo } from './logo'

export const Header = (props) => {
  return (
    <>
      <header id="header" className="alt">
        <span className="logo"><Logo/></span>
        <h1 style={{color:'#000',fontWeight: 400}}>Uniformes corporativos a medida</h1>
        {/*<p>"Su negocio no se debe adaptar al software, nosotros desarrollamos el*/}
        {/*  software a medida para su negocio."</p>*/}
      </header>
    </>
  )
}
