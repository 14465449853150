import React from 'react'
import logo from '../../../assets/img/inacap.png'
import { Logocontainer } from './logocontainer'

export const Inacap = (props) => {
  return (
    <Logocontainer>
      <img src={logo} alt="Logo" width={300}/>
    </Logocontainer>
  )
}
